<template>
  <!-- begin::VehicleModels edit -->
  <div>
    <div class="manager-title">
      <div>
        {{
          VehicleModelID > 0
            ? $t("VEHICLE_MODELS.TITLE_EDIT")
            : $t("VEHICLE_MODELS.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("VEHICLE_MODELS.SUBTITLE_DATA") }}
        </div>

        <div v-if="Fields.ImportedFromCar2DB" class="search-info mb-6">
          <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
          {{ $t("VEHICLE_MODELS.IMPORTED_FROM_CAR2DB") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.BRAND") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Brand"
              :placeholder="$t('VEHICLE_MODELS.BRAND')"
              required
              :rules="[rules.required]"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.MODEL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Model"
              :placeholder="$t('VEHICLE_MODELS.MODEL')"
              required
              :rules="[rules.required]"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.VERSION") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Version"
              :placeholder="$t('VEHICLE_MODELS.VERSION')"
              required
              :rules="[rules.required]"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.YEAR_FROM") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.YearFrom"
              type="number"
              min="1850"
              :max="new Date().getFullYear()"
              step="1"
              :placeholder="$t('VEHICLE_MODELS.YEAR_FROM')"
              required
              :rules="[rules.required, rules.min1850, rules.maxThisYear]"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.YEAR_TO") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.YearTo"
              type="number"
              :min="Fields.YearFrom"
              :max="new Date().getFullYear()"
              step="1"
              :placeholder="$t('VEHICLE_MODELS.YEAR_TO')"
              required
              :rules="[rules.required, rules.minFrom, rules.maxThisYear]"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>
        </div>

        <div class="manager-subtitle">
          {{ $t("VEHICLE_MODELS.SUBTITLE_DIMENSIONS") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.HEIGHT") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Height"
              type="number"
              min="1"
              step="1"
              :placeholder="$t('VEHICLE_MODELS.HEIGHT')"
              required
              :rules="[rules.required, rules.min1]"
              @input="computeVehicleTypeIDBySize"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.LENGTH") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Length"
              type="number"
              min="1"
              step="1"
              :placeholder="$t('VEHICLE_MODELS.LENGTH')"
              required
              :rules="[rules.required, rules.min1]"
              @input="computeVehicleTypeIDBySize"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.WIDTH") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Width"
              type="number"
              min="1"
              step="1"
              :placeholder="$t('VEHICLE_MODELS.WIDTH')"
              required
              :rules="[rules.required, rules.min1]"
              @input="computeVehicleTypeIDBySize"
              :readonly="Fields.ImportedFromCar2DB"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_MODELS.VEHICLE_TYPE") }}
            </label>
            <v-text-field
              class="form-control"
              :value="getVehicleTypeName(VehicleTypeID)"
              readonly
            />
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary"
          @click="$router.push('/manager/vehicles')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          v-if="!Fields.ImportedFromCar2DB"
          class="btn btn-pill btn-tertiary button-form ml-9"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::VehicleModels edit -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VehicleModelService from "@/core/services/api/v2/vehiclemodel.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "VehicleModelsEdit",

  data() {
    return {
      VehicleModelID: 0,
      VehicleTypeID: 0,
      VehicleTypeOptions: [],
      Valid: true,
      Fields: {
        Brand: "",
        Model: "",
        Version: "",
        Length: "",
        Width: "",
        Height: "",
        YearFrom: 0,
        YearTo: 0,
        ImportedFromCar2DB: false
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min1: value =>
          parseInt(value) >= 1 || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min1850: value =>
          parseInt(value) >= 1850 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 1850 }),
        minFrom: value =>
          parseInt(value) >= this.Fields.YearFrom ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: this.Fields.YearFrom }),
        maxThisYear: value => {
          let thisYear = new Date().getFullYear();
          if (parseInt(value) <= thisYear) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.MAX_VALUE", {
              max: new Date().getFullYear()
            });
          }
        }
      }
    };
  },

  created() {
    this.loadVehicleTypeOptions();
  },

  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.VehicleModelID = parseInt(this.$route.params.id);
      this.loadVehicleModelData();
    } else {
      this.VehicleModelID = -1;
    }
  },

  methods: {
    loadVehicleTypeOptions() {
      this.VehicleTypeOptions = [];
      this.VehicleTypeNames = [];
      this.VehicleTypeNames[0] = " ";

      VehicleTypeService.listAll().then(response => {
        response.forEach(vt => {
          this.VehicleTypeOptions.push(vt);
          this.VehicleTypeNames[vt.VehicleTypeID] =
            vt.Position + ": " + vt.Name;
        });
      });
    },

    loadVehicleModelData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      VehicleModelService.getProfile(this.VehicleModelID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/vehicles");
        } else {
          this.VehicleTypeID = response.VehicleTypeID;

          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    computeVehicleTypeIDBySize() {
      let newVTID = -1;
      let myLength = this.Fields.Length;
      let myWidth = this.Fields.Width;
      let myHeight = this.Fields.Height;

      // a. By length & width & height
      for (let v = 0; v < this.VehicleTypeOptions.length; v++) {
        let vt = this.VehicleTypeOptions[v];

        if (
          vt.LengthMin <= myLength &&
          myLength <= vt.LengthMax &&
          vt.WidthMin <= myWidth &&
          myWidth <= vt.WidthMax &&
          vt.HeightMin <= myHeight &&
          myHeight <= vt.HeightMax
        ) {
          newVTID = vt.VehicleTypeID;
          break;
        }
      }

      // b. If there's no VehicleType with the right size, pick by lowest volume
      if (newVTID < 1) {
        let myVolume = (myLength / 1000) * (myWidth / 1000) * (myHeight / 1000);
        // Sizes are set in mm, so volume in mm3 might pass max_int - convert to m3

        for (let v = 0; v < this.VehicleTypeOptions.length; v++) {
          let vt = this.VehicleTypeOptions[v];
          newVTID = vt.VehicleTypeID;

          let vtMaxVolume =
            (vt.LengthMax / 1000) *
            (vt.WidthMax / 1000) *
            (vt.HeightMax / 1000);

          if (myVolume <= vtMaxVolume) {
            break;
          }
        }
      }

      this.VehicleTypeID = newVTID;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (!this.Fields.ImportedFromCar2DB && this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.VehicleModelID > 0) {
          // Update VehicleModel
          VehicleModelService.updateProfile(
            this.VehicleModelID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        } else {
          // Create new VehicleModel: we get back its ID
          VehicleModelService.createProfile(this.Fields).then(response => {
            if (response.VehicleModelID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.VehicleModelID = response.VehicleModelID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/vehicles/edit/" + response.VehicleModelID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    },

    getVehicleTypeName(vtID) {
      let name = this.VehicleTypeNames[vtID];
      if (!name) {
        name = " - ";
      }

      return name;
    }
  }
};
</script>
